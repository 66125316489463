import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {isExcludedFromHandling} from '@configs/network/api.exclusions';
import {StorageKeys} from '@app/app.constants';
import {StorageService} from '@services/storage.service';
import {Store} from '@ngrx/store';
import { NotificationsService } from '@app/modules/notifications/notifications.service';
import { ApplicationState } from "@app/app.state";
import { AuthenticationSignout } from "@store/actions/authentication.actions";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private storage: StorageService,
                private store: Store<ApplicationState>,
                private notifications: NotificationsService) {

    }

    intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
        if (isExcludedFromHandling(request.url)) {
            return handler.handle(request);
        }

        const clonedRequest = request.clone();

        return handler.handle(clonedRequest)
            .pipe(
                catchError((error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 403 || error.status === 401) {
                            this.storage.unset(StorageKeys.AUTH_ACCESS_TOKEN);
                            this.storage.unset(StorageKeys.AUTH_ACCESS_TOKEN_EXP);
                            this.storage.unset(StorageKeys.AUTH_LOGGED_USER);
                            this.store.dispatch(AuthenticationSignout());

                            this.router.navigate(['/auth/signin']);
                        }
                      this.notifications.error('Errore', error.error.message);


                      /*
                      else if (error.status === 404) {
                          this.router.navigate(['/errors', '404']);
                      } else if (error.status >= 500) {
                          this.router.navigate(['/errors', '500']);
                      }
                      */
                    }

                    return throwError(error);
                })
            );
    }
}
