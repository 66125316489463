import {Injectable} from "@angular/core";

import {NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

const I18N_VALUES = {
  'it': {
    weekdays: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'],
    months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
    weekLabel: 'set'
  }
};


@Injectable()
export class DatepickerIT extends NgbDatepickerI18n {
  constructor() {super();}

  getWeekdayLabel(weekday: number): string { return I18N_VALUES.it.weekdays[weekday - 1]; }
  getWeekLabel(): string { return I18N_VALUES.it.weekLabel; }
  getMonthShortName(month: number): string { return I18N_VALUES.it.months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }

}
