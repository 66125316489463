export class Partner {
  id: string;
  accountStatus: string;
  email: string;
  rank: string;
  businessName: string;
  description: string;
  vatcode: string;
  website: string;
  logo: {
    name: string;
    type: string;
    path: string;
  };
  video: {
    name: string;
    type: string;
    path: string;
  };
  contactPerson: string;
  phone: string;
  address: {
    street: string;
    streetNumber: number;
    zipcode: number;
    state: string;
    district: string;
  };
  creationDate: string;

  constructor() {}
}
