<div *ngIf="visible" id="header" class="container-fluid d-flex justify-content-center align-items-center mb-2">
  <div class="container-header d-flex flex-row align-items-center justify-content-between">
    <a routerLink="/">
      <img class="my-2" src="../../../assets/images/desktop/header/logo.webp" width="62" height="auto" alt="logo">
    </a>
    <div class="d-none d-md-flex w-100 justify-content-end align-items-center">

      <div class="links d-flex flex-row" [class.white]="url.match('blog') || url === '/contacts'">
        <div class="col d-flex justify-content-center me-4">
          <a routerLink="clubs" routerLinkActive="active">{{'index.header.club' | translate}}</a>
        </div>
        <div class="col d-flex justify-content-center me-4">
          <a routerLink="associates" routerLinkActive="active">{{'index.header.associates' | translate}}</a>
        </div>
        <div class="col d-flex justify-content-center me-4">
          <a routerLink="partners" routerLinkActive="active">{{'index.header.partner' | translate}}</a>
        </div>
        <div class="col d-flex justify-content-center me-4">
          <a routerLink="events" routerLinkActive="active">{{'index.header.event' | translate}}</a>
        </div>
        <div class="col d-flex justify-content-center me-4">
          <a routerLink="blog" routerLinkActive="active">{{'index.header.blog' | translate}}</a>
        </div>
        <div class="col d-flex justify-content-center me-4">
          <a routerLink="contacts" routerLinkActive="active">{{'index.header.contacts' | translate}}</a>
        </div>
      </div>
      <app-button
        *ngIf="role !== 'USER'"
        (click)="onReservedAreaClick()"
        [icon]="'assets/images/desktop/header/icon_area_riservata.svg'"
        [type]="'light'"
        title="{{'index.header.button.reservedArea' | translate}}">
      </app-button>
      <app-button
        *ngIf="role === 'USER'"
        (click)="onReservedAreaClick()"
        [icon]="'assets/images/desktop/header/il_mio_profilo_icon.svg'"
        [type]="'light'"
        title="{{'index.header.button.myProfile' | translate}}">
      </app-button>
    </div>
    <div class="d-flex d-md-none justify-content-end align-items-center mobile">
      <button (click)="onReservedAreaClick()" class="left" *ngIf="role !== 'USER'">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <img class="me-3" src="assets/images/mobile/header/area_riservata_icon.svg" alt="button mobile">
          <span class="me-2 mini">{{'index.header.button.reservedArea' | translate}}</span>
        </div>
      </button>
      <button (click)="onReservedAreaClick()" class="left" *ngIf="role === 'USER'">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <img class="me-3" src="assets/images/mobile/header/il_mio_profilo_icon.svg" alt="button mobile">
          <span class="me-2 mini">{{'index.header.button.myProfile' | translate}}</span>
        </div>
      </button>
      <button class="right" (click)="toggle()">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <img src="assets/images/mobile/header/menu_icon.svg" alt="menu">
        </div>
      </button>
    </div>

    <!-- Menu mobile -->
    <div id="mobileMenu" [@openClose]="menuIsOpen ? 'open' : 'closed'"
         class="d-flex flex-column d-md-none open-close-menu">
      <div class="w-100 pt-4 d-flex justify-content-end">
        <img (click)="toggle()" src="assets/images/mobile/menu_of_canvas/close_icon.svg"
             width="29" height="29" alt="close menu icon">
      </div>
      <div [class.d-none]="!menuIsOpen" class="d-flex flex-column links white">
        <a class="mt-5" routerLink="clubs" (click)="toggle()">{{'index.header.club' | translate}}</a>
        <a class="mt-5" routerLink="associate" (click)="toggle()">{{'index.header.associates' | translate}}</a>
        <a class="mt-5" routerLink="partners" (click)="toggle()">{{'index.header.partner' | translate}}</a>
        <a class="mt-5" routerLink="events" (click)="toggle()">{{'index.header.event' | translate}}</a>
        <a class="mt-5" routerLink="blog" (click)="toggle()">{{'index.header.blog' | translate}}</a>
        <a class="mt-5" routerLink="contacts" (click)="toggle()">{{'index.header.contacts' | translate}}</a>
        <div class="d-flex flex-row mt-5">
          <a href="https://www.facebook.com/1familysport" target="_blank">
            <img class="me-3" src="assets/images/mobile/menu_of_canvas/facebook_icon.svg" alt="facebook icon" width="12"
                 height="23">
          </a>
          <a href="https://instagram.com/1familysport" target="_blank">
            <img class="me-3" src="assets/images/mobile/menu_of_canvas/instagram_icon.svg" alt="instagram icon"
                 width="22" height="22">
          </a>
          <a href="https://www.linkedin.com/company/1familysport/" target="_blank">
            <img src="assets/images/mobile/menu_of_canvas/linkedin_icon.svg" alt="linkedin icon" width="22" height="22">
          </a>
        </div>

        <app-button *ngIf="role !== 'USER'"
                    (click)="onMobileButtonClick()" class="mt-5 w-100" [responsive]="true"
                    title="{{'index.header.button.reservedArea' | translate}}" [type]="'light'"></app-button>

        <app-button *ngIf="role === 'USER'" (click)="onMobileButtonClick()" class="mt-5 w-100" [responsive]="true"
                    title="{{'index.header.button.myProfile' | translate}}" [type]="'light'"></app-button>

      </div>
    </div>

  </div>
</div>
