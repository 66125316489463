import {Admin} from "@configs/models/admin.model";
import {User} from "@configs/models/user.model";
import {Club} from "@configs/models/club.model";
import {StorageService} from "@services/storage.service";
import {StorageKeys} from "@app/app.constants";
import {JwtDescriptorInterface} from "@configs/network/api.descriptors";
import jwt_decode from "jwt-decode";
import {AdminsFactory} from "@configs/models/factories/admins.factory";
import {UsersFactory} from "@configs/models/factories/users.factory";
import {ClubsFactory} from "@configs/models/factories/clubs.factory";
import {Partner} from "@configs/models/partner.model";
import {PartnersFactory} from "@configs/models/factories/partners.factory";
import {AssociatesFactory} from "@configs/models/factories/associates.factory";
import {Associate} from "@configs/models/associate.model";

export interface AuthenticationState {
  user: Admin | User | Club | Partner | Associate ;
  role: string;
}

export function defineAuthenticationInitialState(): AuthenticationState {
  const storage: StorageService = new StorageService();
  let user: Admin | User | Club | Partner | Associate;
  let role: string;

  if (!storage.get(StorageKeys.AUTH_ACCESS_TOKEN)) {
    user = null;
    role = null;
  } else {
    const tokenInfo: JwtDescriptorInterface = jwt_decode(
      storage.get(StorageKeys.AUTH_ACCESS_TOKEN)
    );
    role = tokenInfo.role;
    switch (tokenInfo.role) {
      case 'ADMIN':
        user = AdminsFactory.restore();
        break;

      case 'USER':
        user = UsersFactory.restore();
        break;

      case 'CLUB':
        user = ClubsFactory.restore();
        break;

      case 'PARTNER':
        user = PartnersFactory.restore();
        break;

      case 'ASSOCIATE':
        user = AssociatesFactory.restore();
        break;

    }

  }

  return {user: user, role: role};
}
