export const StorageKeys = {
  STORAGE_PREFIX: 'onefamily.backoffice',
  AUTH_ACCESS_TOKEN: 'token',
  AUTH_ACCESS_TOKEN_EXP: 'expat',
  AUTH_LOGGED_USER: 'user',
  AUTH_LOGGED_USER_ROLE: 'role'
};

export const StoreActionsKeys = {
  ACTION_AUTHENTICATION_SIGNIN: 'authentication::signin',
  ACTION_AUTHENTICATION_SIGNOUT: 'authentication::signout',
  UPDATE_LOGGED_USER: 'loggedUser::update',
};
