import {Routes} from '@angular/router';

import {LoggedInGuard} from "@app/guards/logged.in.guard";
import {IsNotUserGuard} from "@app/guards/is.not.user.guard";

export const ApplicationRoutes: Routes = [{
  path: '',
  loadChildren: () => import('./modules/home/home.module')
    .then(exports => exports.HomeModule)
}, {
  path: 'auth',
  loadChildren: () => import('./modules/authentication/authentication.module')
    .then(exports => exports.AuthenticationModule)
}, {
  path: 'admin',
  canActivate: [LoggedInGuard, IsNotUserGuard],
  loadChildren: () => import('./modules/administration/administration.module')
    .then(exports => exports.AdministrationModule)
}, {
  path: 'profile',
  canActivate: [LoggedInGuard],
  loadChildren: () => import('./modules/profile/profile.module')
    .then(exports => exports.ProfileModule)
}, {
  path: 'blog',
  loadChildren: () => import('./modules/blog/blog.module')
    .then(exports => exports.BlogModule)
}, {
  path: 'clubs',
  loadChildren: () => import('./modules/clubs/clubs.module')
    .then(exports => exports.ClubsModule)
}, {
  path: 'associates',
  loadChildren: () => import('./modules/associates/associates.module')
    .then(exports => exports.AssociatesModule)
}, {
  path: 'partners',
  loadChildren: () => import('./modules/partners/partners.module')
    .then(exports => exports.PartnersModule)
}, {
  path: 'events',
  loadChildren: () => import('./modules/events/events.module')
    .then(exports => exports.EventsModule)
}, {
  path: 'contacts',
  loadChildren: () => import('@modules/contacts/contacts.module')
    .then(exports => exports.ContactsModule)
}];
