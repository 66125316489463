import { ErrorsLanguagePartialDefinition } from '@configs/translations/it/errors.translations';
import { AuthenticationLanguagePartialDefinition } from "@configs/translations/it/authentication.translations";
import { AdministrationLanguagePartialDefinition } from "@configs/translations/it/administration.translations";
import { HomeLanguagePartialDefinition } from "@configs/translations/it/home.translations";
import { ProfileLanguagePartialDefinition } from "@configs/translations/it/profile.translations";
import { IndexLanguagePartialDefinition } from "@configs/translations/it/index.translations";
import { BlogLanguagePartialDefinition } from "@configs/translations/it/blog.translations";
import { ClubsLanguagePartialDefinition } from "@configs/translations/it/clubs.translations";
import { PartnersLanguagePartialDefinition } from "@configs/translations/it/partners.translations";
import { EventsLanguagePartialDefinition } from "@configs/translations/it/events.translations";
import { ContactsLanguagePartialDefinition } from "@configs/translations/it/contacts.translations";
import { AssociatesLanguagePartialDefinition } from "@configs/translations/it/associates.translations";

export const LanguageDefinition = {
  errors: ErrorsLanguagePartialDefinition,
  authentication: AuthenticationLanguagePartialDefinition,
  administration: AdministrationLanguagePartialDefinition,
  home: HomeLanguagePartialDefinition,
  profile: ProfileLanguagePartialDefinition,
  index: IndexLanguagePartialDefinition,
  blog: BlogLanguagePartialDefinition,
  clubs: ClubsLanguagePartialDefinition,
  partners: PartnersLanguagePartialDefinition,
  events: EventsLanguagePartialDefinition,
  contacts: ContactsLanguagePartialDefinition,
  associates: AssociatesLanguagePartialDefinition
};
