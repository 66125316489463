import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CapitalizePipe} from './pipes/strings/capitalize.pipe';
import {BackgroundUrlPipe} from './pipes/strings/background.url.pipe';
import {FromNumberToArrayPipe} from '@everyup/pipes/numbers/as.array.pipe';
import {PreventBubblingDirective} from '@everyup/directives/preventBubbling';
import {InvalidControlCheckDirective} from '@everyup/directives/forms/invalidControlCheck';
import {ButtonEnabledDirective} from '@everyup/directives/forms/buttonEnabled';
import {ImageResizeInputDirective} from '@everyup/directives/forms/imageResizeInput';
import {FormatDataPipe} from "@everyup/pipes/strings/formatData.pipe";
import {TrimPipe} from "@everyup/pipes/strings/trim.pipe";
import { ResizableComponent } from "@everyup/directives/resizable-table/resizable.components";
import { ResizableDirective } from "@everyup/directives/resizable-table/resizable.directive";

@NgModule({
  declarations: [
    PreventBubblingDirective,
    InvalidControlCheckDirective,
    ButtonEnabledDirective,
    ImageResizeInputDirective,

    ResizableComponent,
    ResizableDirective,

    CapitalizePipe,
    BackgroundUrlPipe,
    FromNumberToArrayPipe,
    FormatDataPipe,
    TrimPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PreventBubblingDirective,
    InvalidControlCheckDirective,
    ButtonEnabledDirective,
    ImageResizeInputDirective,

    ResizableComponent,

    CapitalizePipe,
    BackgroundUrlPipe,
    FromNumberToArrayPipe,
    FormatDataPipe,
    TrimPipe
  ]
})
export class EveryUPModule {

}
