<div class="modal-header border-0 d-flex flex-column">
  <div class="d-flex justify-content-end w-100">
    <div (click)="modal.dismiss()" class="close d-flex justify-content-center align-items-center">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <h5 class="modal-title">{{ title }}</h5>
  </div>

</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer border-0 d-flex justify-content-center mt-5">
  <div class="row w-100">
    <div class="col confirm d-flex justify-content-center align-items-center" (click)="action.emit('confirm')">
      <span>{{buttons.confirm}}</span>
    </div>
    <div class="col dismiss d-flex justify-content-center align-items-center"
         (click)="action.emit('dismiss')">
      <span>{{buttons.dismiss}}</span>
    </div>
  </div>
</div>
