export const AssociatesLanguagePartialDefinition = {
  list: {
    title: 'Collaborazioni',
    noAssociates: 'Nessuna collaborazione.',
    buttons: {
      showMap: 'Visualizza mappa',
      loadMore: 'Carica altri'
    }
  },
  details: {
    breadcrumb: 'Collaborazione',
    events: 'Eventi',
    noEvents: 'Nessun evento programmato',
    showMore: 'Scopri di più'
  }
}
