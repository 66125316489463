export class TrainingField {
  id: string;
  title: string;
  description: string;
  image: {
    name: string;
    type: string;
    path: string;
  };
  address: string;

  constructor() {}
}
