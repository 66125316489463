export const BlogLanguagePartialDefinition = {
  list: {
    title: 'Blog',
    lastArticles: 'Ultimi articoli',
    noArticles: 'Nessun articolo presente',
    buttons: {
      loadMore: 'Carica altri'
    }
  },
  details: {
    showMore: 'Continua a leggere',
    modals: {
      showMore: {
        title: 'Registrazione necessaria',
        message: 'Per visionare il contenuto è necessario eseguire l’accesso. Loggati con le tue credenziali oppure procedi all’iscrizione.',
        buttons: {
          confirm: 'Accedi',
          dismiss: 'Iscriviti'
        }
      }
    },
  }
}
