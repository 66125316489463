import {AdminDescriptorInterface} from "@configs/network/api.descriptors";
import {Admin} from "@configs/models/admin.model";
import {StorageService} from "@services/storage.service";
import {StorageKeys} from "@app/app.constants";

export class AdminsFactory {
  static create(descriptor: AdminDescriptorInterface): Admin {
    const instance: Admin = new Admin();
    const keysToDuplicate = ['id', 'email'];

    keysToDuplicate.forEach(key => instance[key] = descriptor[key]);

    return instance;
  }

  static restore(): Admin {
    const storage: StorageService = new StorageService();

    if (!storage.get(StorageKeys.AUTH_LOGGED_USER)) {
      return null;
    }

    const source = storage.get(StorageKeys.AUTH_LOGGED_USER);
    const instance = new Admin();

    Object
      .keys(source)
      .forEach((key: string) => instance[key] = source[key]);

    return instance;
  }

}
