import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {ApplicationState} from "@app/app.state";
import {filter, map} from "rxjs/operators";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  role: string;
  visible: boolean = true;
  routerEvent: Subscription;

  constructor(private router: Router,
              private store: Store<ApplicationState>) {
    this.routerEvent = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event))
      .subscribe((event: NavigationEnd) => {
        this.visible = !(event.url.includes('admin'));
      });

    this.store.pipe(
      select((state: ApplicationState) => state.authentication.role))
      .subscribe(role => this.role = role);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.routerEvent) {
      this.routerEvent.unsubscribe();
      this.routerEvent = null;
    }
  }

  onReservedAreaClick(): void {
    if (this.role === 'USER') {
      this.router.navigate(['/profile']).then();
    } else {
      this.router.navigate(['/admin']).then();
    }
  }

}
