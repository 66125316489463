import {ModuleWithProviders, NgModule} from '@angular/core';
import {AngularSvgIconModule} from 'angular-svg-icon';

import {NotificationsContainerComponent} from './components/container/container.component';
import {NotificationsService} from './notifications.service';
import {SharedModule} from '@modules/shared/shared.module';


@NgModule({
    declarations: [NotificationsContainerComponent],
    imports: [
        SharedModule,
        AngularSvgIconModule.forRoot()
    ],
    providers: [NotificationsService],
    exports: [NotificationsContainerComponent]
})
export class NotificationsModule {
    static forRoot(): ModuleWithProviders<NotificationsModule> {
        return {
            ngModule: NotificationsModule,
            providers: [NotificationsService]
        };
    }
}
