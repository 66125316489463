export const ClubsLanguagePartialDefinition = {
  list: {
    title: 'Società',
    noClubs: 'Nessuna società.',
    buttons: {
      showMap: 'Visualizza mappa',
      loadMore: 'Carica altri'
    }
  },
  details: {
    breadcrumb: 'Società',
    events: 'Eventi',
    noEvents: 'Nessun evento programmato',
    showMore: 'Scopri di più'
  }
}
