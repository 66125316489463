import {PartnerDescriptorInterface} from "@configs/network/api.descriptors";
import {Partner} from "@configs/models/partner.model";
import {StorageService} from "@services/storage.service";
import {StorageKeys} from "@app/app.constants";

export class PartnersFactory {
  static create(descriptor: PartnerDescriptorInterface): Partner {
    const instance: Partner = new Partner();
    const keysToDuplicate = ['id', 'email', 'accountStatus', 'rank', 'businessName', 'description', 'vatcode', 'website', 'logo', 'video', 'contactPerson', 'phone', 'address', 'creationDate'];

    keysToDuplicate.forEach(key => instance[key] = descriptor[key]);

    return instance;
  }

  static restore(): Partner {
    const storage: StorageService = new StorageService();

    if (!storage.get(StorageKeys.AUTH_LOGGED_USER)) {
      return null;
    }

    const source = storage.get(StorageKeys.AUTH_LOGGED_USER);
    const instance = new Partner();

    Object
      .keys(source)
      .forEach((key: string) => instance[key] = source[key]);

    return instance;
  }

}
