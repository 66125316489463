import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NavigationEnd, Router} from "@angular/router";

import {filter, map} from "rxjs/operators";

import {select, Store} from "@ngrx/store";
import {ApplicationState} from "@app/app.state";
import {FbEventHandlerService} from "@modules/shared/services/fb/fb.event.handler.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        width: '100vw',
        'padding-right': '3rem',
        'padding-left': '3rem',
      })),
      state('closed', style({
        opacity: 0,
        width: 0,
        'padding-right': '0',
        'padding-left': '0',
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuIsOpen: boolean = false;
  visible: boolean = true;
  url: string;
  role: string;
  routerEvent: Subscription;
  storeSub: Subscription;

  constructor(private router: Router,
              private fbApiConversionService: FbEventHandlerService,
              private store: Store<ApplicationState>) {
    this.url = this.router.url;
    this.role = null;
    this.routerEvent = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event))
      .subscribe((event: NavigationEnd) => {
        this.url = this.router.url
        this.visible = !(event.url.includes('admin'));
      })
    this.storeSub = this.store.pipe(
      select((state: ApplicationState) => state.authentication.role))
      .subscribe(role => this.role = role);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.routerEvent.unsubscribe();
    this.routerEvent = null;
    this.storeSub.unsubscribe();
    this.storeSub = null;
  }

  toggle(): void {
    this.menuIsOpen =  !this.menuIsOpen;
  }

  onReservedAreaClick(): void {
    if (this.role === 'USER') {
      this.router.navigate(['/profile']).then();
    } else {
      this.router.navigate(['/admin']).then();
    }
  }

  onMobileButtonClick(): void {
    if (this.role === 'USER') {
      this.router.navigate(['/profile']).then();
    } else {
      this.router.navigate(['/admin']).then();
    }
    this.toggle();
  }

}
