<div *ngIf="visible" id="footer" class="container-fluid px-0 d-none d-md-flex flex-column justify-content-center align-items-center">
  <div class="top pb-3 d-flex flex-column justify-content-center align-items-center">
    <div class="container-footer d-flex flex-column justify-content-start align-items-center">
      <div class="row w-100 my-4">
        <div class="col-3">
          <a routerLink="/">
            <img class="my-2" src="assets/images/desktop/footer/logo_footer.webp" width="116" height="auto" alt="logo footer">
          </a>
        </div>
        <div class="col-3 d-flex flex-column">
          <a class="mb-4" href="assets/pdfs/1FAM_Company_Profile_2024_def_low.pdf" target="_blank" >{{'index.footer.menu.companyProfile' | translate}}</a>
          <a class="mb-4" routerLink="clubs">{{'index.footer.menu.club' | translate}}</a>
          <a class="mb-4" routerLink="partners">{{'index.footer.menu.partner' | translate}}</a>
        </div>
        <div class="col-3 d-flex flex-column">
          <a class="mb-4" routerLink="events">{{'index.footer.menu.event' | translate}}</a>
          <a class="mb-4" routerLink="blog">{{'index.footer.menu.blog' | translate}}</a>
          <a class="mb-4" routerLink="contacts">{{'index.footer.menu.contacts' | translate}}</a>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between align-items-end w-100 second-row">
        <div class="d-flex flex-row justify-content-start">
          <a class="me-5" href="tel:+393347365126">{{'index.footer.info.phoneNumber' | translate}}</a>
          <a class="me-5" href="mailto:info@1familysport.it">{{'index.footer.info.email' | translate}}</a>
          <a href="https://www.facebook.com/1familysport" target="_blank">
            <img class="mx-3" src="assets/images/mobile/footer/facebook_icon.svg" width="12" height="23" alt="logo facebook">
          </a>
          <a href="https://instagram.com/1familysport" target="_blank">
            <img class="me-3" src="assets/images/mobile/footer/instagram_icon.svg" width="22" height="22" alt="logo instagram">
          </a>
          <a href="https://www.linkedin.com/company/1familysport/" target="_blank">
            <img class="me-3" src="assets/images/mobile/footer/linkedin_icon.svg" width="22" height="22" alt="logo linkedin">
          </a>
        </div>
        <button (click)="onReservedAreaClick()">
          {{'index.footer.button.reservedArea' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex justify-content-center align-items-center credits">
    <div class="container-footer d-flex justify-content-start">
      <div class="col">
        <span class="me-5">{{'index.footer.credits' | translate}}</span>
        <a class="me-5" href="https://www.iubenda.com/privacy-policy/69588089" target="_blank">
          <span>Privacy Policy</span>
        </a>
        <a href="https://www.iubenda.com/privacy-policy/69588089/cookie-policy" target="_blank">
          <span>Cookie Policy</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div id="footer-mobile" class="d-flex flex-column d-md-none">
  <div class="container-fluid d-flex flex-column">
    <div class="top d-flex flex-column w-100 justify-content-between align-items-center">
      <a routerLink="/">
        <img class="my-2" src="assets/images/mobile/footer/logo.webp"
             width="72" height="auto" alt="logo footer mobile">
      </a>

      <div class="info d-flex flex-column justify-content-start align-items-center">
        <a href="tel:+393347365126">{{'index.footer.info.phoneNumber' | translate}}</a>
        <a href="mailto:info@1familysport.it">
          {{'index.footer.info.email' | translate}}
        </a>
      </div>
    </div>
    <div class="mt-3 w-100 d-flex justify-content-center align-items-center">
      <button (click)="onReservedAreaClick()" class="w-100">
        {{'index.footer.button.reservedArea' | translate}}
      </button>
    </div>
    <div class="mt-3 d-flex justify-content-start align-items-start flex-column">
      <a href="assets/pdfs/1FAM_Company_Profile_2024_def_low.pdf" target="_blank">
        {{'index.footer.menu.companyProfile' | translate}}
      </a>
      <a class="mt-1" routerLink="clubs">{{'index.footer.menu.club' | translate}}</a>
      <a class="mt-1" routerLink="partners">{{'index.footer.menu.partner' | translate}}</a>
      <a class="mt-1" routerLink="events">{{'index.footer.menu.event' | translate}}</a>
      <a class="mt-1" routerLink="blog">{{'index.footer.menu.blog' | translate}}</a>
      <a class="mt-1" routerLink="contacts">{{'index.footer.menu.contacts' | translate}}</a>
    </div>
    <div class="mt-3 d-flex justify-content-start flex-row">
      <span>{{'index.footer.menu.followUs' | translate}}</span>
      <a href="https://www.facebook.com/1familysport" target="_blank">
        <img class="mx-3" src="assets/images/mobile/footer/facebook_icon.svg" width="12" height="23" alt="logo facebook">
      </a>
      <a href="https://instagram.com/1familysport" target="_blank">
        <img class="me-3" src="assets/images/mobile/footer/instagram_icon.svg" width="22" height="22" alt="logo instagram">
      </a>
      <a href="https://www.linkedin.com/company/1familysport/" target="_blank">
        <img class="me-3" src="assets/images/mobile/footer/linkedin_icon.svg" width="22" height="22" alt="logo linkedin">
      </a>
    </div>
  </div>
  <div class="mt-3 w-100 d-flex justify-content-start align-items-center credits">
    <div class="d-flex flex-column justify-content-start">
      <div class="col">
        <span>{{'index.footer.credits' | translate}}</span>
      </div>
      <div class="col mt-3">
        <a href="https://www.iubenda.com/privacy-policy/69588089" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy" target="_blank">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
      </div>
      <div class="col mt-3">
        <a href="https://www.iubenda.com/privacy-policy/69588089/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy" target="_blank">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
      </div>
    </div>
  </div>
</div>
