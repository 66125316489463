export const PartnersLanguagePartialDefinition = {
  list: {
    title: 'Partner',
    noPartners: 'Nessun partners.',
    buttons: {
      loadMore: 'Carica altri'
    }
  },
  details: {
    breadcrumb: 'Partner',
    download: 'Download',
    modals: {
      download: {
        title: 'Necessario essere loggato',
        message: 'Per scaricare il coupon è necessario eseguire l’accesso. Loggati con le tue credenziali oppure procedi all’iscrizione.',
        buttons: {
          confirm: 'Accedi',
          dismiss: 'Iscriviti'
        }
      }
    }

  }
}
