import {ClubDescriptorInterface, TrainingFieldsDescriptorInterface} from "@configs/network/api.descriptors";
import {Club} from "@configs/models/club.model";
import {TrainingFieldsFactory} from "@configs/models/factories/trainingFields.factory";
import {StorageService} from "@services/storage.service";
import {StorageKeys} from "@app/app.constants";

export class ClubsFactory {
  static create(descriptor: ClubDescriptorInterface): Club {
    const instance: Club = new Club();
    const keysToDuplicate = ['id', 'email', 'accountStatus', 'businessName', 'vatcode', 'contactPerson', 'phone', 'address', 'introductionText', 'logo', 'website', 'video', 'image', 'trainingFields', 'facebookLink', 'instagramLink', 'twitterLink', 'registrationDate'];

    keysToDuplicate.forEach((key: string) => {

      if (key === 'trainingFields') {
        if (!descriptor[key]) {
          instance[key] = [];
        } else {
          instance[key] = descriptor[key].map((trainingFields: TrainingFieldsDescriptorInterface) => TrainingFieldsFactory.create(trainingFields));
        }
      } else {
        instance[key] = descriptor[key];
      }

    });

    return instance;
  }

  static restore(): Club {
    const storage: StorageService = new StorageService();

    if (!storage.get(StorageKeys.AUTH_LOGGED_USER)) {
      return null;
    }

    const source = storage.get(StorageKeys.AUTH_LOGGED_USER);
    const instance = new Club();

    Object
      .keys(source)
      .forEach((key: string) => instance[key] = source[key]);

    return instance;
  }

}
