export const ProfileLanguagePartialDefinition = {
  user: {
    button: {
      edit: 'Modifica',
      logout: 'Logout',
      delete: 'Elimina il tuo profilo',
      changePsw: 'Cambia password'
    },
    edit: {
      title: 'Modifica profilo',
      form: {
        labels: {
          name: 'Nome',
          surname: 'Cognome',
          taxcode: 'Codice fiscale',
          email: 'E-mail',
          password: 'Password',
          'password-confirm': 'Conferma la password',
          street: 'Indirizzo',
          streetNumber: 'n. civico',
          district: 'Comune',
          zipcode: 'Cap',
          state: 'Provincia',
          birthDate: 'Data di nascita',
          birthPlace: 'Luogo di nascita',
          club: 'Società di appartenenza'
        },
        placeholders: {
          name: 'Inserisci il nome',
          surname: 'Inserisci il cognome',
          taxcode: 'Inserisci il codice fiscale',
          email: 'Inserisci e-mail',
          password: 'Inserisci la password',
          'password-confirm': 'Conferma la password',
          street: 'Inserisci l\'indirizzo',
          streetNumber: 'Inserisci il n. civico',
          district: 'Inserisci il comune',
          zipcode: 'Inserisci il cap',
          state: 'Inserisci la provincia',
          birthDate: 'Inserisci la data di nascita',
          birthPlace: 'Inserisci luogo di nascita',
          club: 'Cerca società...'
        },
        action: 'Modifica'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica utente',
        message: 'Utente modificato',
        error: 'Errore durante la modifica'
      },
      changePsw: {
        title: 'Cambia password',
        message: 'Abbiamo inviato una mail all\'indirizzo <b>{{email}}</b>',
        error: 'Errore durante il reset della password'
      },
      delete: {
        title: 'Elimina profilo',
        message: 'Profilo eliminato correttamente',
        error: 'Errore durante l\'eliminzione'
      }
    },
    modals:{
      changePsw: {
        title: 'Cambia password',
        message: 'Sei sicuro di vole cambiare la password?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      },
      delete: {
        title: 'Elimina il tuo profilo',
        message: 'Sei sicuro di voler eleminare definitivamente il tuo profilo? <b>Attenzione l\'operazione è irreversibile</b>',
        buttons: {
          confirm: 'Si, voglio eliminare il mio profilo',
          dismiss: 'No'
        }
      }
    }
  },
  club: {
    trainingCamps: 'Campi di allenamento',
    noTrainingCamps: 'Nessun campo di allenamento',
    button: {
      upload: 'Carica immagine',
      edit: 'Modifica',
      logout: 'Logout',
      changePsw: 'Cambia password',
      addField: 'Aggiungi campo'
    },
    edit: {
      title: 'Modifica profilo',
      form: {
        labels: {
          businessName: 'Ragione Sociale',
          vatcode: 'P.Iva/Codice Fiscale',
          email: 'E-mail',
          contactPerson: 'Referente',
          phone: 'Telefono',
          password: 'Password',
          'password-confirm': 'Conferma password',
          street: 'Indirizzo',
          streetNumber: 'Civico',
          zipcode: 'CAP',
          district: 'Comune',
          state: 'Provincia',
          introductionText: 'Presentazione',
          website: 'Sito web',
        },
        placeholders: {
          businessName: 'Inserisci ragione sociale',
          vatcode: 'Inserisci p.Iva/codice fiscale',
          email: 'Inserisci e-mail',
          contactPerson: 'Inserisci referente',
          phone: 'Inserisci telefono',
          password: 'Inserisci password',
          'password-confirm': 'Conferma la password',
          street: 'Inserisci indirizzo',
          streetNumber: 'Civico',
          zipcode: 'Inserisci il CAP',
          district: 'Inserisci il comune',
          state: 'Inserisci la provincia',
          introductionText: 'Inserisci una presentazione',
          website: 'Inserisci il sito web',
        },
        action: 'Modifica'
      }
    },
    notifications: {
      addField: {
        tittle: 'Aggiungi campo di allenamento',
        message: 'Campo <b>{{title}}</b> aggiunto correttamente',
        error: 'Errore durante la creazione'
      },
      editField: {
        tittle: 'Modifica campo di allenamento',
        message: 'Campo <b>{{title}}</b> modificato correttamente',
        error: 'Errore durante la modifica'
      },
      deleteField: {
        title: 'Cancella campo di allenamento',
        message: 'Campo <b>{{title}}</b> cancellato correttamente',
        error: 'Errore durante la cancellazione'
      },
      edit: {
        title: 'Modifica profilo',
        message: 'Profilo modificato',
        error: 'Errore durante la modifica'
      },
      changePsw: {
        title: 'Cambia password',
        message: 'Abbiamo inviato una mail all\'indirizzo <b>{{email}}</b>',
        error: 'Errore durante il reset della password'
      },
      upload: {
        title: 'Caricamento Logo',
        message: 'Logo caricato correttamente',
        errors:  {
          title: 'Errore durante il caricamento del logo',
          oversize: 'File troppo grande. Massima dimensione 10Mb'
        }
      }
    },
    modals:{
      field: {
        create: 'Aggiungi campo di allenamento',
        edit: 'Modifica campo di allenament',
        delete: {
          title: 'Cancella campo di allenamento',
          message: 'Sei sicuro di voler cancellare il campo di allenamento?',
          buttons: {
            confirm: 'Si',
            dismiss: 'No'
          }
        },
        form: {
          title: 'Nome',
          description: 'Breve descrizione',
          address: 'Indirizzo'
        },
        buttons: {
          create: 'Aggiungi',
          edit: 'Modifica'
        },
      },
      changePsw: {
        title: 'Cambia password',
        message: 'Sei sicuro di voler cambiare la password?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    }
  },
  associate: {
    button: {
      upload: 'Carica immagine',
      edit: 'Modifica',
      logout: 'Logout',
      changePsw: 'Cambia password',
    },
    edit: {
      title: 'Modifica profilo',
      form: {
        labels: {
          businessName: 'Ragione Sociale',
          vatcode: 'P.Iva/Codice Fiscale',
          email: 'E-mail',
          contactPerson: 'Referente',
          phone: 'Telefono',
          password: 'Password',
          'password-confirm': 'Conferma password',
          street: 'Indirizzo',
          streetNumber: 'Civico',
          zipcode: 'CAP',
          district: 'Comune',
          state: 'Provincia',
          introductionText: 'Presentazione',
          website: 'Sito web',
          facebookLink: 'Facebook Link',
          instagramLink: 'Instagram Link',
          twitterLink: 'Twitter Link',
        },
        placeholders: {
          businessName: 'Inserisci ragione sociale',
          vatcode: 'Inserisci p.Iva/codice fiscale',
          email: 'Inserisci e-mail',
          contactPerson: 'Inserisci referente',
          phone: 'Inserisci telefono',
          password: 'Inserisci password',
          'password-confirm': 'Conferma la password',
          street: 'Inserisci indirizzo',
          streetNumber: 'Civico',
          zipcode: 'Inserisci il CAP',
          district: 'Inserisci il comune',
          state: 'Inserisci la provincia',
          introductionText: 'Inserisci una presentazione',
          website: 'Inserisci il sito web',
          facebookLink: 'Inserisci facebook link',
          instagramLink: 'Inserisci instagram link',
          twitterLink: 'Inserisci Twitter link'
        },
        action: 'Modifica'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica profilo',
        message: 'Profilo modificato',
        error: 'Errore durante la modifica'
      },
      changePsw: {
        title: 'Cambia password',
        message: 'Abbiamo inviato una mail all\'indirizzo <b>{{email}}</b>',
        error: 'Errore durante il reset della password'
      },
      upload: {
        title: 'Caricamento Logo',
        message: 'Logo caricato correttamente',
        errors:  {
          title: 'Errore durante il caricamento del logo',
          oversize: 'File troppo grande. Massima dimensione 10Mb'
        }
      }
    },
    modals:{
      changePsw: {
        title: 'Cambia password',
        message: 'Sei sicuro di voler cambiare la password?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    }
  },
  partner: {
    button: {
      upload: 'Carica immagine',
      edit: 'Modifica',
      logout: 'Logout',
      changePsw: 'Cambia password'
    },
    edit: {
      title: 'Modifica profilo',
      form: {
        labels: {
          businessName: 'Ragione Sociale',
          contactPerson: 'Referente',
          rank: 'Rank',
          vatcode: 'P.Iva/Codice Fiscale',
          email: 'E-mail',
          phone: 'Telefono',
          website: 'Sito web',
          description: 'Descrizione',
          street: 'Indirizzo',
          streetNumber: 'Civico',
          zipcode: 'CAP',
          district: 'Comune',
          state: 'Provincia',
        },
        placeholders: {
          businessName: 'Inserisci ragione sociale',
          contactPerson: 'Inserisci referente',
          rank: 'Scegli un rank',
          vatcode: 'Inserisci p.Iva/codice fiscale',
          email: 'Inserisci e-mail',
          phone: 'Inserisci telefono',
          website: 'Inserisci il sito web',
          description: 'Inserisci una descrizione',
          street: 'Inserisci indirizzo',
          streetNumber: 'Civico',
          zipcode: 'Inserisci il CAP',
          district: 'Inserisci il comune',
          state: 'Inserisci la provincia',
          introductionText: 'Inserisci una presentazione',
        },
        action: 'Modifica'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica profilo',
        message: 'Profilo modificato',
        error: 'Errore durante la modifica'
      },
      changePsw: {
        title: 'Cambia password',
        message: 'Abbiamo inviato una mail all\'indirizzo <b>{{email}}</b>',
        error: 'Errore durante il reset della password'
      }
    },
    modals:{
      changePsw: {
        title: 'Cambia password',
        message: 'Sei sicuro di vole cambiare la password?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    }
  }
}
