import {Club} from "@configs/models/club.model";

export class User {
  id: string;
  email: string;
  name: string;
  surname: string;
  fullname: string;
  address: {
    street: string,
    streetNumber: number;
    zipcode: number;
    state: string;
    district: string;
  };
  birthDate: string;
  birthPlace: string;
  taxcode: string;
  club: Club;
  registrationDate: string;

  constructor() {
  }

  defineExtraProperties(): void {
    this.fullname = `${this.name} ${this.surname}`;
  }

}
