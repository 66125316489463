import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {NavigationEnd, Router} from "@angular/router";

import {combineLatest} from "rxjs";
import {filter, map} from "rxjs/operators";

import jsSHA from "jssha/dist/sha256";
import {fbEnvironment} from "@modules/shared/services/fb/environment";
import {ApplicationState} from "@app/app.state";
import {select, Store} from "@ngrx/store";
import {Club} from "@configs/models/club.model";
import {Partner} from "@configs/models/partner.model";
import {User} from "@configs/models/user.model";

@Injectable()
export class FbEventHandlerService {
  url: string;
  email: string = null;
  phone: string = null;

  constructor(private http: HttpClient,
              private store: Store<ApplicationState>,
              private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event))
      .subscribe((event: NavigationEnd) => {
        this.url = window.location.protocol + "//" + window.location.host + this.router.url;
      })
    combineLatest([
      this.store
        .pipe(select((state: ApplicationState) =>
          state.authentication.user)),
      this.store
        .pipe(select((state: ApplicationState) =>
          state.authentication.role))
    ]).subscribe(([user, role]) => {
      if (user) {
        this.email = user.email;
        if (role === 'CLUB') {
          this.phone = (user as Club)?.phone;
        } else if (role === 'PARTNER') {
          this.phone = (user as Partner)?.phone;
        }
      }
    })
  }

  viewContent(eventId: string, contentName: string): void {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      let payload: any = {
        data: [
          {
            event_name: "ViewContent",
            event_id: eventId,
            event_time: Math.trunc(new Date().getTime() / 1000),
            action_source: "website",
            event_source_url: this.url,
            user_data: {
              client_user_agent: window.navigator.userAgent,
              client_ip_address: res.ip
            },
            custom_data: {
              content_name: contentName
            }
          }
        ]
      };

      if (this.email) {
        let shaObjEm = new jsSHA("SHA-256", "TEXT", {encoding: "UTF8"});
        shaObjEm.update(this.email);
        payload.data[0]['user_data']['em'] = [shaObjEm.getHash('HEX')];
      }

      if (this.phone) {
        let shaObjPh = new jsSHA("SHA-256", "TEXT", {encoding: "UTF8"});
        shaObjPh.update(this.phone);
        payload.data[0]['user_data']['ph'] = [shaObjPh.getHash('HEX')];
      }

    })
  }

  contact(eventId: string): void {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      let payload: any = {
        data: [
          {
            event_name: "Contact",
            event_id: eventId,
            event_time: Math.trunc(new Date().getTime() / 1000),
            action_source: "website",
            event_source_url: this.url,
            user_data: {
              client_user_agent: window.navigator.userAgent,
              client_ip_address: res.ip
            }
          }
        ]
      };

      if (this.email) {
        let shaObjEm = new jsSHA("SHA-256", "TEXT", {encoding: "UTF8"});
        shaObjEm.update(this.email);
        payload.data[0]['user_data']['em'] = [shaObjEm.getHash('HEX')];
      }

      if (this.phone) {
        let shaObjPh = new jsSHA("SHA-256", "TEXT", {encoding: "UTF8"});
        shaObjPh.update(this.phone);
        payload.data[0]['user_data']['ph'] = [shaObjPh.getHash('HEX')];
      }

      this.http.post((fbEnvironment.base_url +
        fbEnvironment.api_version + '/' +
        fbEnvironment.pixel_id +
        '/events?access_token=' +
        fbEnvironment.token), payload).subscribe(res => {
        if ((window as any).fbq) {
          const fbq = (window as any).fbq;
          fbq('track', 'Contact', {eventID: eventId});
        }
      });

    })
  }

  completeRegistration(eventId: string, user: User): void {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      let payload: any = {
        data: [
          {
            event_name: "CompleteRegistration",
            event_id: eventId,
            event_time: Math.trunc(new Date().getTime() / 1000),
            action_source: "website",
            event_source_url: this.url,
            user_data: {
              client_user_agent: window.navigator.userAgent,
              client_ip_address: res.ip
            }
          }
        ]
      };

      if (user.email) {
        let shaObjEm = new jsSHA("SHA-256", "TEXT", {encoding: "UTF8"});
        shaObjEm.update(this.email);
        payload.data[0]['user_data']['em'] = [shaObjEm.getHash('HEX')];
      }

      this.http.post((fbEnvironment.base_url +
        fbEnvironment.api_version + '/' +
        fbEnvironment.pixel_id +
        '/events?access_token=' +
        fbEnvironment.token), payload).subscribe(res => {
        if ((window as any).fbq) {
          const fbq = (window as any).fbq;
          fbq('track', 'CompleteRegistration', {eventID: eventId});
        }
      });

    })
  }

}
