export const EventsLanguagePartialDefinition = {
  title: 'Eventi',
  list: {
    noEvents: 'Nessun evento.',
    buttons: {
      loadMore: 'Carica altri',
      showPastEvents: 'Visualizza eventi passati',
      showFutureEvents: 'Visualizza eventi futuri'
    }
  },
  details: {
    breadcrumb: 'Eventi'
  },
}
