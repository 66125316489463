<button
  [class.filled]="buttonBaseClass === 'filled'"
  [class.empty]="buttonBaseClass === 'empty'"
  [class.clear]="buttonBaseClass === 'clear'"
  [class.light]="buttonBaseClass === 'light'"

  [class.active]="buttonClass === 'active'"
  [class.disabled]="buttonClass === 'disabled'"
  [class.loading]="buttonClass === 'loading'"

  [class.responsive]="responsiveClass"

  #button>

  <div class="d-flex flex-row justify-content-center align-items-center">
    <img *ngIf="buttonIcon" [src]="buttonIcon" alt="button icon">
    <span id="button-title" *ngIf="buttonClass !== 'loading'" [innerHTML]="buttonTitle"></span>
  </div>

  <div id="button-loader" *ngIf="buttonClass === 'loading'">
    <i class="mdi mdi-loading"></i>
  </div>
</button>
