import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ButtonComponent} from "@modules/shared/components/button/button.component";
import {ModalConfirmComponent} from "@modules/shared/components/modals/confirm/confirm.component";
import {FbEventHandlerService} from "@modules/shared/services/fb/fb.event.handler.service";

@NgModule({
  declarations: [
    ButtonComponent,
    ModalConfirmComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  providers: [
    FbEventHandlerService
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    ButtonComponent
  ]
})
export class SharedModule {

}
