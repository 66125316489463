export const IndexLanguagePartialDefinition = {
  header: {
    club: 'Società',
    associates: 'Collaborazioni',
    partner: 'Partner',
    event: 'Eventi',
    blog: 'Blog',
    contacts: 'Contatti',
    button: {
      reservedArea: 'Area riservata',
      myProfile: 'Il mio profilo'
    }
  },
  footer: {
    credits: 'Copyright © 1FamilySport di De Baptistis Romolo – P.I. 02107640670',
    menu: {
      club: 'Società',
      partner: 'Partner',
      event: 'Eventi',
      blog: 'Blog',
      contacts: 'Contatti',
      followUs: 'Follow us',
      companyProfile: 'Company Profile',
      businessOpportunity: 'Business Opportunity',
    },
    info: {
      phoneNumber: '+39 334 7365126',
      email: 'info@1familysport.it'
    },
    button: {
      reservedArea: 'Area riservata'
    }
  }

}
