export const AuthenticationLanguagePartialDefinition = {
  signin: {
    label: 'Accedi',
    form: {
      placeholders: {
        email: 'Email',
        password: 'Password',
      },
      action: 'Accedi'
    },
    passwordRecovery: {
      answer: 'Password dimenticata?',
      action: 'Recupera password'
    },
    signup: {
      answer: 'Sei un nuovo utente?',
      action: 'Registrati'
    },
    notifications: {
      error: {
        title: 'Errore durante l\'accesso'
      },
      accountConfirmed: {
        title: 'Account confermato',
        message: 'Account confermato con successo.',
        error: 'Errore durante la conferma dell\'account'
      }
    }
  },
  signup: {
    label: 'Nuovo Utente',
    subtitle: 'Compila tutti i campi',
    form: {
      placeholders: {
        name: 'Nome',
        surname: 'Cognome',
        club: 'Società che vuoi supportare, tifare',
        email: 'Email',
        password: 'Password',
        'password-confirm': 'Conferma password',
        street: 'Indirizzo',
        streetNumber: 'Civico',
        district: 'Comune',
        zipcode: 'Cap',
        state: 'Provincia',
        birthDate: 'Data di nascita',
        birthPlace: 'Luogo di nascita',
        taxcode: 'Codice fiscale'
      },
      tooltips: {
        info: 'Ricerca per nome o per città. La tua preferenza aiuterà la società sportiva a raggiungere nuovi partner e migliorare la qualità del lavoro con i ragazzi.'
      },
      action: 'Registrati'
    },
    notifications: {
      create: {
        title: 'Registrazione utente',
        message: 'L\'utente <b>{{fullname}}</b> registrato con successo.<br> Abbiamo inviato un email per confermare l\'account all\'indirizzo <b>{{email}}</b>.',
        error: 'Errore durante la creazione'
      }
    }
  },
  passwordRecovery: {
    title: 'Recupera password',
    action: 'Recupera password',
    form: {
      placeholders: {
        email: 'Email'
      }
    },
    notifications: {
      recovery: {
        title: 'Reset password',
        message: 'Abbiamo inviato una mail all\'indirizzo <b>{{email}}</b>',
        error: 'Errore durante il reset della password'
      }
    }
  },
  resetPassword: {
    title: 'Nuova password',
    action: 'Conferma',
    form: {
      placeholders: {
        password: 'Password',
        'password-confirm': 'Conferma password'
      }
    },
    notifications: {
      tokenExpired: {
        title: 'Recupero password',
        message: 'Sessione scaduta',
      },
      setPassword: {
        title: 'Imposta password',
        message: 'Nuova password salvata',
        error: 'Errore nell l\'impostare la nuova password'
      }
    }
  }
}
