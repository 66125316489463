import {
  AssociateDescriptorInterface,
  ClubDescriptorInterface,
  TrainingFieldsDescriptorInterface
} from "@configs/network/api.descriptors";
import {Club} from "@configs/models/club.model";
import {TrainingFieldsFactory} from "@configs/models/factories/trainingFields.factory";
import {StorageService} from "@services/storage.service";
import {StorageKeys} from "@app/app.constants";
import {Associate} from "@configs/models/associate.model";

export class AssociatesFactory {
  static create(descriptor: AssociateDescriptorInterface): Associate {
    const instance: Associate = new Associate();
    const keysToDuplicate = ['id', 'email', 'businessName', 'vatcode', 'contactPerson', 'phone', 'address', 'introductionText', 'logo', 'website', 'video', 'image', 'facebookLink', 'instagramLink', 'twitterLink', 'accountStatus' ,'registrationDate'];

    keysToDuplicate.forEach((key: string): void => instance[key] = descriptor[key]);

    return instance;
  }

  static restore(): Associate {
    const storage: StorageService = new StorageService();

    if (!storage.get(StorageKeys.AUTH_LOGGED_USER)) {
      return null;
    }

    const source = storage.get(StorageKeys.AUTH_LOGGED_USER);
    const instance: Associate = new Associate();

    Object
      .keys(source)
      .forEach((key: string) => instance[key] = source[key]);

    return instance;
  }
}
