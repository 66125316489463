export const HomeLanguagePartialDefinition = {
  title: '1FamilySport,',
  subtitle: 'Lo sport è per tutti',
  button: 'Scopri di più',
  form: {
    placeholders: {
      name: 'Nome e cognome',
      email: 'Indirizzo email',
      subject: 'Oggetto',
      body: 'Messaggio'
    },
    privacy: 'ho letto l’informativa privacy, inoltre:',
    marketing: {
      accept: 'acconsento',
      dismiss: 'non acconsento al trattamento dei miei Dati personali e a ricevere comunicazioni promozionali relative al Titolare e comunicazioni relative ad eventi organizzati dal Titolare (finalità di marketing)'
    },
    action: 'Invia'
  },
  notifications: {
    send: {
      title: 'Invio messaggio',
      message: 'Messaggio inviato con successo',
      error: 'Error nell\'invio del messaggio'
    }
  }
}
