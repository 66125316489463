import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {StorageKeys} from '@app/app.constants';
import {StorageService} from '@services/storage.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private storage: StorageService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasAccessToken: boolean = !!this.storage.get(StorageKeys.AUTH_ACCESS_TOKEN);
    const hasUserInstance: boolean = !!this.storage.get(StorageKeys.AUTH_LOGGED_USER);

    if (hasAccessToken && hasUserInstance) {
        return true;
    }

    if (hasAccessToken) {
      return true;
    }

    this.storage.unset(StorageKeys.AUTH_ACCESS_TOKEN);
    this.storage.unset(StorageKeys.AUTH_LOGGED_USER);

    this.router.navigate(['/auth/signin']).then();

    return false

  }
}
