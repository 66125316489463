import {ClubDescriptorInterface, UserDescriptorInterface} from "@configs/network/api.descriptors";
import {User} from "@configs/models/user.model";
import {StorageService} from "@services/storage.service";
import {StorageKeys} from "@app/app.constants";
import {ClubsFactory} from "@configs/models/factories/clubs.factory";

export class UsersFactory {
  static create(descriptor: UserDescriptorInterface): User {
    const instance: User = new User();
    const keysToDuplicate = ['id', 'email', 'name', 'surname', 'address', 'birthDate', 'birthPlace',
      'taxcode', 'club', 'registrationDate'];

    keysToDuplicate.forEach(key => {

      if (key === 'club' && descriptor[key]) {
        instance[key] = ClubsFactory.create(descriptor[key] as ClubDescriptorInterface);
      } else {
        instance[key] = descriptor[key]
      }

    });
    instance.defineExtraProperties();

    return instance;
  }

  static restore(): User {
    const storage: StorageService = new StorageService();

    if (!storage.get(StorageKeys.AUTH_LOGGED_USER)) {
      return null;
    }

    const source = storage.get(StorageKeys.AUTH_LOGGED_USER);
    const instance = new User();

    Object
      .keys(source)
      .forEach((key: string) => instance[key] = source[key]);

    instance.defineExtraProperties();

    return instance;
  }

}
