import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {StorageKeys} from '@app/app.constants';
import {StorageService} from '@services/storage.service';
import {JwtDescriptorInterface} from "@configs/network/api.descriptors";
import jwt_decode from "jwt-decode";

@Injectable()
export class IsNotUserGuard implements CanActivate {
  constructor(private storage: StorageService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const tokenInfo: JwtDescriptorInterface = jwt_decode(this.storage.get(StorageKeys.AUTH_ACCESS_TOKEN));

    if (tokenInfo) {
      if (tokenInfo.role !== 'USER') {
        return true;
      } else {
        this.router.navigate(['/']).then();
        return false
      }
    }

  }
}
