import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';

import * as Definitions from '@configs/network/api.definitions';
import {environment} from '@env/environment';

interface ApiGroup {
  [key: string]: any;
}

@Injectable()
export class ApiService {
  readonly url: string;

  public Auth: any;
  public Articles: any;
  public Users: any;
  public Clubs: any;
  public Partners: any;
  public Coupons: any;
  public EmailForms: any;
  public Events: any;
  public Geo: any;
  public ImpersonationAuth: any;
  public ContactForms: any;
  public Associates: any;

  constructor(private http: HttpClient) {
    this.url = environment.endpoint || `${window.location.protocol}//${window.location.host}`;
    this.url += environment.baseApi || '';

    this.Auth = this.constructApisForGroup('AuthController');
    this.Articles = this.constructApisForGroup('ArticleController');
    this.Users = this.constructApisForGroup('UserController');
    this.Clubs = this.constructApisForGroup('ClubController');
    this.Partners = this.constructApisForGroup('PartnerController');
    this.Coupons = this.constructApisForGroup('CouponController');
    this.EmailForms = this.constructApisForGroup('EmailFormController');
    this.Events = this.constructApisForGroup('EventController');
    this.Geo = this.constructApisForGroup('GeoController');
    this.ImpersonationAuth = this.constructApisForGroup('ImpersonationAuthController');
    this.ContactForms = this.constructApisForGroup('ContactFormController');
    this.Associates = this.constructApisForGroup('AssociateController');
  }

  private constructApisForGroup(group: string): ApiGroup {
    const paths = Definitions.paths;
    const keys = Object.keys(paths);
    const apis = {};

    keys.forEach(path => {
      const descriptor = paths[path];
      const methods = Object.keys(descriptor);

      methods.forEach(method => {
        const operationId = descriptor[method].operationId;

        if (operationId && operationId.startsWith(`${group}_`)) {
          this.constructApiFromOperation(apis, path, method, descriptor[method]);
        }
      });
    });

    return apis;
  }

  private constructApiFromOperation(root: ApiGroup, path: string, method: string, descriptor: any): ApiGroup {
    const components = descriptor.operationId.split('_');

    let clone = root;

    for (let i = 1; i < components.length - 1; i++) {
      if (!clone[components[i]]) {
        clone[components[i]] = {};
      }

      clone = clone[components[i]];
    }

    clone[components[components.length - 1]] = (data: any, options: any): Observable<any> => {
      if (method === 'get' || method === 'delete') {
        options = options || {};
        options.params = this.buildRequestParams(data);

        // Query parameters will be overwritten inside the api.interceptor
        return this.http[method](path, options).pipe(
          take(1),
          catchError((error: any) => {
            if (error.error != null) {
              error.message = error.error.message;
              return throwError(error);
            }
          })
        );
      } else {
        return this.http[method](path, data, options).pipe(
          take(1),
          catchError((error: any) => {
            if (error.error != null) {
              error.message = error.error.message;
            }
            return throwError(error);
          })
        );
      }
    };

    return root;
  }

  private buildRequestParams(data: any): HttpParams {
    const requestParams: string[] = [];

    if (!data) {
      return new HttpParams({});
    }

    Object.keys(data).forEach((key) => {
      requestParams.push(`${key}=${data[key]}`);
    });

    return new HttpParams({
      fromString: requestParams.join('&')
    });
  }
}
