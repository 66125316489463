import {createReducer, on} from '@ngrx/store';
import {AuthenticationState, defineAuthenticationInitialState} from '@store/states/authentication.state';
import {AuthenticationSignin, AuthenticationSignout, UpdateUser} from '@store/actions/authentication.actions';
import {User} from "@configs/models/user.model";
import {Admin} from "@configs/models/admin.model";
import {Club} from "@configs/models/club.model";
import {Partner} from "@configs/models/partner.model";

const initialState: AuthenticationState = defineAuthenticationInitialState();
const reducer = createReducer(
  initialState,
  on(AuthenticationSignin, (state: AuthenticationState, action: { payload: Admin | User | Club | Partner, role: string }) => ({
    ...state,
    user: action.payload,
    role: action.role
  })),
  on(AuthenticationSignout, (state: AuthenticationState, action: {}) => ({
    ...state,
    user: null,
    role: null,
  })),
  on(UpdateUser, (state: AuthenticationState, action: { payload: Admin | User | Club | Partner }) => ({
    ...state,
    user: action.payload
  }))
);

export function AuthenticationReducer(state: AuthenticationState, action): any {
  return reducer(state, action);
}
