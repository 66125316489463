import {TrainingFieldsDescriptorInterface,} from "@configs/network/api.descriptors";
import {TrainingField} from "@configs/models/trainingField.model";

export class TrainingFieldsFactory {
  static create(descriptor: TrainingFieldsDescriptorInterface): TrainingField {
    const instance: TrainingField = new TrainingField();
    const keysToDuplicate = ['id', 'title', 'description', 'image', 'address'];

    keysToDuplicate.forEach(key => instance[key] = descriptor[key]);

    return instance;
  }
}
