export const ContactsLanguagePartialDefinition = {
  title: 'Contatti',
  info: 'Trovi interessante il nostro progetto? Vuoi approfondirne alcuni aspetti? Saremo felici di chiarire i tuoi dubbi e metterti a disposizione tutte le informazioni necessarie perché per noi lo sport è una cosa seria!',
  form: {
    title: 'Contattaci',
    placeholders: {
      name: 'Nome e cognome',
      email: 'Indirizzo email',
      subject: 'Oggetto',
      body: 'Messaggio'
    },
    privacyOne: 'ho letto',
    privacyTwo: 'l’informativa privacy',
    privacyThree: ', inoltre:',
    marketing: {
      accept: 'acconsento',
      dismiss: 'non acconsento al trattamento dei miei Dati personali e a ricevere comunicazioni promozionali relative al Titolare e comunicazioni relative ad eventi organizzati dal Titolare (finalità di marketing)'
    },
    action: 'Invia'
  },
  notifications: {
    send: {
      title: 'Invio messaggio',
      message: 'Messaggio inviato con successo',
      error: 'Error nell\'invio del messaggio'
    }
  }
}
