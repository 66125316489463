import {ErrorHandler} from '@angular/core';

export class GlobalErrorHandler implements ErrorHandler{
    constructor() {}

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            console.warn('Detected an outdated version, reloading to fetch the new one.');

            window.location.reload();
        } else {
            console.error(error);
        }
    }

}
