import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {GuardsCheckEnd, NavigationEnd, RouterModule} from "@angular/router";

import { AppComponent } from './app.component';
import {ApiModule} from "@modules/network/api.module";
import {NotificationsModule} from "@modules/notifications/notifications.module";
import {TranslationsModule} from "@modules/translations/translations.module";
import {NgProgressModule} from "ngx-progressbar";
import {IndexComponent} from "@components/index/index.component";
import {ApplicationRoutes} from "@app/app.routes";
import {NgProgressRouterModule} from "ngx-progressbar/router";
import {GlobalErrorHandler} from "@app/app.handlers";
import {LoggedInGuard} from "@app/guards/logged.in.guard";
import {StorageService} from "@services/storage.service";
import { HeaderComponent } from '@components/header/header.component';
import {SharedModule} from "@modules/shared/shared.module";
import { FooterComponent } from '@components/footer/footer.component';
import {EveryUPModule} from "@everyup/everyup.module";
import {StoreModule} from "@ngrx/store";
import {AuthenticationReducer} from "@store/reducers/authentication.reducer";
import {IsNotUserGuard} from "@app/guards/is.not.user.guard";
import {NgbDateParserFormatter, NgbDatepickerI18n} from "@ng-bootstrap/ng-bootstrap";
import {DatepickerAdapter} from "@app/datepicker-adapter";
import {DatepickerIT} from "@app/datepicker-IT";


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule,
    EveryUPModule,
    NotificationsModule.forRoot(),
    TranslationsModule.forRoot(),
    RouterModule.forRoot(ApplicationRoutes, {scrollPositionRestoration: 'enabled'}),
    NgProgressModule,
    NgProgressRouterModule.withConfig({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
      delay: 500,
      id: 'router-progressbar'
    }),
    SharedModule,
    StoreModule.forRoot({
      authentication: AuthenticationReducer
    })
  ],
  providers: [
    StorageService,
    LoggedInGuard,
    IsNotUserGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: NgbDateParserFormatter,
      useClass: DatepickerAdapter,
    },
    {
      provide: NgbDatepickerI18n,
      useClass: DatepickerIT,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
